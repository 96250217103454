









import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({ components: {} })
export default class NotFoundPage extends Vue {}
